<!-- <div class="wrapper" *ngIf="payload && payload.subMenu !== null">
  <kendo-expansionpanel
    *ngFor="let item of payload.subMenu.items; index as i"
    [title]="item.title"
    [expanded]="true"
  >
    <div class="content">
      <button  *ngFor="let x of item.items" [ngClass]="{'k-text-primary': isActive(x), '': !isActive(x)}" (click)="action(x)">
        <span [class]="x.icon"></span>
        {{ x.title }}</button>
    </div>
  </kendo-expansionpanel>
</div> -->

<!-- <div *ngIf="!payload" class="payload">
  <img src="assets/images/logo.png" class="placeholder" style="max-height: 120px;">
</div> -->

<div class="fit">
  <kendo-card>
    <kendo-card-header></kendo-card-header>
    <kendo-card-actions></kendo-card-actions>
    <kendo-card-body>
      <div class="wrapper" *ngIf="payload && payload.subMenu !== null">
        <kendo-expansionpanel
          *ngFor="let item of payload.subMenu.items; index as i"
          [title]="item.title"
          [expanded]="true"
        >
          <div class="content">
            <button kendoButton style="width: 100%; max-width: none; margin-bottom: 0.5rem;"
              *ngFor="let x of item.items"
              [ngClass]="{ 'k-text-primary': isActive(x), '': !isActive(x) }"
              (click)="action(x)"
            >
              <span [class]="x.icon"></span>
              {{ x.title }}
            </button>
          </div>
        </kendo-expansionpanel>
      </div>
    </kendo-card-body>
  </kendo-card>
</div>
