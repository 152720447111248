<kendo-appbar position="top" class="k-bg-dark" style="padding: 0px">
  <kendo-appbar-section class="title">
    <div class="app-title">
      <img src="assets/images/logo.jpeg" alt="" style="margin-right: 1rem; max-height:3rem">
    </div>
  </kendo-appbar-section>
  <kendo-appbar-spacer width="32px"></kendo-appbar-spacer>


  <kendo-appbar-section>

    <kendo-buttongroup selection="single">
      <!-- <button kendoButton [toggleable]="true" [selected]="hasPrefix('/dashboard')" *ngIf="getMode() === 'ALL' || getMode() === 'DASHBOARD_ONLY' || getMode() === 'CRM_DASHBOARD_ONLY'"
        (selectedChange)="navigateTo($event, '/dashboard')"> <i class="fas fa-gauge"></i> Dashboard</button> -->
      <button kendoButton [toggleable]="true" [selected]="hasPrefix('/crm')" *ngIf="getMode() === 'ALL' || getMode() === 'CRM_ONLY' || getMode() === 'CRM_DASHBOARD_ONLY'"
        (selectedChange)="navigateTo($event, '/crm')"> <i class="fas fa-users"></i> CRM</button>
      <button kendoButton [toggleable]="true" [selected]="hasPrefix('/helpdesk')" *ngIf="getMode() === 'ALL' || getMode() === 'HELPDESK_ONLY'"
        (selectedChange)="navigateTo($event, '/helpdesk')"> <i class="fas fa-helicopter"></i> Helpdesk</button>
    </kendo-buttongroup>

  </kendo-appbar-section>

  <kendo-appbar-section *ngIf="getMode() === 'ALL' || getMode() === 'CRM_ONLY'|| getMode() === 'CRM_DASHBOARD_ONLY' " >
    <kendo-textbox class="searchbar" [style.width.px]="250" [(ngModel)]="searchField" (ngModelChange)="changedSearchField($event)"
      [placeholder]="'Search..'"></kendo-textbox>
    <button kendoButton class="searchbar" (click)="openModal(modalTemplate)">  <i class="fas fa-search"></i> Search</button>
  </kendo-appbar-section>

  <kendo-appbar-spacer></kendo-appbar-spacer>


  <kendo-appbar-section class="user-actions">

    <h3 class="k-text-primary" style="margin-right: 1rem;" *ngIf="userName">
      Welcome {{userName | titlecase }}
    </h3>

    <kendo-dropdownbutton themeColor="light" style="margin-right: 1rem" [data]="dropDownButtonItems"
      (itemClick)="onSplitButtonItemClick($event)">
      <!-- <span class="k-icon k-i-user"></span> -->
      <i class="fas fa-user"></i> <!-- uses solid style -->
      User Settings
    </kendo-dropdownbutton>

    <!-- Disabled due switch to SCSS -->
    <!-- <kendo-dropdownbutton [data]="themeColors" (itemClick)="onThemeClick($event)">
      <span class="k-icon k-i-settings"></span>
      Theme
      <ng-template kendoDropDownButtonItemTemplate let-dataItem>
        <span>{{ dataItem.text }}</span>
        <span *ngIf="dataItem.color === currentColler" class="k-icon k-i-kpi-status-open"></span>

      </ng-template>
    </kendo-dropdownbutton> -->
  </kendo-appbar-section>
</kendo-appbar>

<ng-template #modalTemplate>
  <div class="searchbar">
    <kendo-textbox [style.width.%]="100" [(ngModel)]="searchField" [placeholder]="'Search..'"
      (ngModelChange)="changedSearchField($event)">
      <ng-template kendoTextBoxSuffixTemplate>
        <kendo-textbox-separator></kendo-textbox-separator>
        <span>{{ results.length }} results found</span>
        <kendo-textbox-separator></kendo-textbox-separator>
      </ng-template>
    </kendo-textbox>
    <button kendoButton (click)="next()"> Search</button>

  </div>

  <kendo-listview [data]="results">
    <ng-template kendoListViewItemTemplate let-dataItem>
      <div class="searchitem">
        <div class="row">
          <div class="icon">
            <span class="k-icon {{dataItem['entity-icon']}}"></span>

          </div>
          <div class="text">
            <span>
              {{dataItem['entity-name']}}
            </span>
            <span style="font-weight: 700;">
              {{dataItem.caption}}
            </span>
          </div>
          <div class="btn">
            <span class="match"> {{ dataItem['result-weight'] }}% match </span>
            <button kendoButton (click)="navigate(dataItem)">Select</button>
          </div>
        </div>

        <div class="wrap">
          <div class="snippet" *ngFor="let snip of dataItem?.snippets">
            <div class="icon">

            </div>
            <div class="text">
              <span class="k-icon k-i-code-snippet"></span>

              <span>
                {{ snip['snippet-caption']}} - {{ snip['snippet-detail'] }}
              </span>
            </div>
          </div>
        </div>

      </div>

    </ng-template>
  </kendo-listview>
</ng-template>

